<template>
  <v-container v-if="isUserManagementDisabled" grid-list-xl>
    <v-flex xs9>
      <view-title :showBreadCrum="false" />
    </v-flex>
    <DisabledBanner />
  </v-container>
  <v-container v-else grid-list-xl>
    <v-layout row wrap justify-space-between>
      <v-flex xs9>
        <view-title />
      </v-flex>
      <div class="table-length-row">
        <v-flex md5 xs5>
          <v-text-field
            label="Search Users"
            single-line
            solo
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="filter"
          ></v-text-field>
        </v-flex>
        <div class="button-wrapper">
          <div>
            <v-btn
              class="add-user-button button-radius"
              type="submit"
              color="primary"
              @click="addNewUser"
            >
              <v-icon left dark>mdi-plus</v-icon>ADD NEW USER
            </v-btn>
          </div>
          <div>
            <v-btn class="button-radius" color="primary">
              <download-csv
                v-show="users"
                :data="formatUsersForReports('Site Manager')"
                name="site-operators.csv"
              >
                <v-icon left dark>mdi-download</v-icon>export operators
              </download-csv>
            </v-btn>
          </div>
        </div>
      </div>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="filter"
          :pagination.sync="pagination"
        >
          <v-progress-linear
            v-slot:progress
            color="blue"
            indeterminate
            height="7"
          ></v-progress-linear>
          <template v-slot:items="props">
            <tr style="cursor: pointer;">
              <td style="max-width: 80px !important;" @click="goToUser(props.item)">
                <div class="User-Role-Badge" :style="`background-color:${props.item.badgeColour}`">
                  {{ props.item.initials }}
                </div>
              </td>
              <td
                style="max-width: 250px !important;"
                class="text-xs-left"
                @click="goToUser(props.item)"
              >
                {{ props.item.name }}
              </td>
              <td
                style="max-width: 250px !important;"
                class="text-xs-left"
                @click="goToUser(props.item)"
              >
                {{ props.item.role }}
              </td>
              <td
                style="max-width: 250px !important;"
                class="text-xs-left"
                @click="goToUser(props.item)"
              >
                <div class="overflow-ellipsis">{{ props.item.info.email }}</div>
                <div>{{ props.item.info.phone }}</div>
              </td>
              <td>
                <toggle
                  :type="'Blue-red'"
                  v-model="props.item.status"
                  :leftText="'Active'"
                  :leftValue="true"
                  :rightText="'Inactive'"
                  :rightValue="false"
                  :onClickHandler="setUserStatus(props.item.id)"
                  position="start"
                  stylingClass="noclass"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import JsonCSV from 'vue-json-csv';
import { DateTime } from 'luxon';
import { UserRoles, Realms } from '@/constants';
import DisabledBanner from '../../components/disabledBanner.vue';
Vue.component('downloadCsv', JsonCSV);
export default {
  name: 'UsersListing',
  components: {
    DisabledBanner,
  },
  data: () => ({
    pagination: { sortBy: 'sortText', sort: 'descending', rowsPerPage: 10 },
    headers: [
      {
        text: '',
        align: 'left',
        value: 'initials',
        sortable: false,
      },
      {
        text: 'User Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Role',
        align: 'left',
        value: 'role',
        sortable: true,
      },
      {
        text: 'Contact Information',
        align: 'left',
        value: 'srcText',
        sortable: false,
      },
      {
        text: 'User Status',
        align: 'left',
        value: 'status',
        sortable: false,
      },
    ],
    users: [],
    unparsedResults: [],
    filter: '',
    cdlRealm: Realms.cdl,
    bolterRealm: Realms.bolter,
    users_original: [],
  }),
  methods: {
    async getUsers(realm) {
      try {
        const { data } = await this.api.get(`/user/realm/${realm}?nocache=1&max=10000`);
        this.users_original = data.users.concat(this.users_original);
        this.users = this.users.concat(
          data.users
            .filter(
              ({ email, is }) =>
                !/^deleted\d{13}@compassdigital.io/.test(email) && (!is || !is.deleted),
            )
            .map(({ date, ...user }) => {
              const role = this.getPermissionScopes(user);
              const phone = this.formatPhoneNumber(user.phone);
              const status = !(user.is && user.is.disabled);
              const userName = `${user.name.first} ${user.name.last}`;
              return {
                name: userName,
                initials: `${user.name.first.charAt(0).toUpperCase()}${user.name.last
                  .charAt(0)
                  .toUpperCase()}`,
                role: this.getRole(role),
                badgeColour: this.getBadgeColour(role),
                info: { email: user.email, phone },
                srcText: `${user.email}${phone}`, // used for searching,
                sortText: `${!status}${userName.toUpperCase()}`, // used for sorting
                id: user.id,
                status,
              };
            }),
        );
      } catch (err) {
        console.error(err);
        this.$toast.error('Error fetching list of users');
      }
    },
    goToUser({ id, name }) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: name,
        to: { name: 'user-settings', params: { user_id: id } },
      });
      this.$router.push({
        name: 'user-settings',
        params: {
          user_id: id,
        },
      });
    },
    addNewUser() {
      this.$router.push({
        name: 'user-new',
      });
    },
    setUserStatus(id) {
      const userOriginal = this.users_original.find((u) => u.id === id);
      const userParsed = this.users.find((u) => u.id === id);
      return async (isActive) => {
        if (userParsed.status === isActive) return;
        userOriginal.is = { disabled: !isActive };
        userParsed.status = isActive;
        this.api.put(`/user/${id}`, userOriginal).catch((err) => {
          console.error(err);
          this.$toast.error('Could not update user status');
        });
      };
    },
    getBadgeColour(role) {
      /**
      Admin: #DA483B
      Site Manager: #0DA764
      IM Team: #F27F39
      Menu Team: #4D8FCE
      Digital Care Team: #733CAA
      No role: #414042
    */
      switch (role) {
        case `${UserRoles.admin}_role`:
          return '#DA483B';
        case `${UserRoles.sys_admin}_role`:
          return '#DA483B';
        case `${UserRoles.site_operator}_role`:
          return '#0DA764';
        case `${UserRoles.im_user}_role`:
          return '#F27F39';
        default:
          return '#414042';
      }
    },
    getRole(permissions) {
      switch (permissions) {
        case `${UserRoles.admin}_role`:
          return 'Admin';
        case `${UserRoles.site_operator}_role`:
          return 'Site Manager';
        case `${UserRoles.im_user}_role`:
          return 'CDL Delivery Manager';
        case `${UserRoles.sys_admin}_role`:
          return 'Admin';
        case `${UserRoles.runner}_role`:
          return 'Runner';
        default:
          return 'No role assigned';
      }
    },
    getPermissionScopes(user) {
      const permissionScopes = user && user.permissions && user.permissions.scopes;
      return (
        permissionScopes
          .map((p) => `${p}`.split(':')[0])
          .find((s) => `${s}`.indexOf('_role') > -1) || null
      );
    },
    formatPhoneNumber(phoneNumberString) {
      try {
        const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          const intlCode = match[1] ? '+1 ' : '';
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return '';
      } catch (err) {
        return '';
      }
    },
    formatUsersForReports(role) {
      // if you want to create a user report for a specific role with these exact keys:
      // pass in the appropriate string to this method,
      // based on the roles outlined in the switch statement returns of the getRole method
      if (!this.usersForReports) return [];
      return this.usersForReports
        .filter(({ email }) => !/^deleted\d{13}@compassdigital.io/.test(email))
        .filter((user) => this.getRole(this.getPermissionScopes(user)) === role)
        .map(({ date, ...user }) => {
          const status = !(user.is && user.is.disabled);
          return {
            'First Name': user.name.first,
            'Last Name': user.name.last,
            Email: user.email,
            'Menu Access': user.permissions.scopes.includes(this.customPermissions.menuAccess)
              ? 'Yes'
              : 'No',
            'Date Created (UTC)': DateTime.fromISO(date.created).toFormat('LL/dd/yyyy'),
            Status: status ? 'ACTIVE' : 'INACTIVE',
          };
        });
    },
  },
  computed: {
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('splitio', ['getFeatureFlags']),
    usersForReports() {
      return this.users_original || '';
    },
    isUserManagementDisabled() {
      return this.getFeatureFlags['ap3-user-management-disable'];
    },
  },
  async mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Users',
      to: { name: 'users-listing' },
    });
    if (!this.isUserManagementDisabled) {
      this.$store.commit('adminPanel/setLoading', true);
      // Need to get both cdl realm and bolter realm users (bolter runners moved to new realm in BOLT-569)
      const cdl_users = this.getUsers(this.cdlRealm);
      const bolter_users = this.getUsers(this.bolterRealm);
      await Promise.all([cdl_users, bolter_users]);
      this.$store.commit('adminPanel/setLoading', false);
    }
  },
};
</script>
<style scoped>
.User-Role-Badge {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  padding-top: 6px;
  text-align: center;
}
.table-length-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.add-user-wrapper {
  display: flex;
  justify-content: flex-end;
}
.button-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5em 1em 0 0;
}
.add-user-button {
  padding-top: 0.25em;
}
.button-radius {
  border-radius: 6px;
}
</style>